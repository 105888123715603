import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import { useSearchParams } from 'src/routes/hooks';

import { useLocalDomainInfo } from 'src/hooks/use-local-domain-info';

import axios from 'src/utils/axios';

import { authApi } from 'src/api/auth';

import { setSession } from './utils';
import { AuthContext } from './auth-context';

const initialState = {
  user: null,
  company: null,
  loading: true,
  domainData: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.account,
      company: action.payload.company,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
      company: null,
    };
  }
  if (action.type === 'DOMAIN') {
    return {
      ...state,
      domainData: action.payload.domainData,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const searchParams = useSearchParams();

  const { domainData } = useLocalDomainInfo();

  const getCompanyInfo = useCallback(async () => {
    const domain = window.location.host;
    const res = await axios.get(
      `/company/trader_domain?domain=${domain === 'localhost:3030' ? 'live.octolit.com' : domain}`
    );

    const setting = JSON.parse(res?.data?.trader_domain?.internal_brand?.theme_setting);
    let result = res?.data?.trader_domain;
    if (setting) {
      result = {
        ...result,
        colorPreset: setting?.color_preset,
        colorTheme: setting?.color_theme,
      };
    }

    dispatch({
      type: 'DOMAIN',
      payload: {
        domainData: result,
      },
    });
  }, []);

  const initialize = useCallback(async () => {
    try {
      const urlToken = searchParams.get('token');
      const accessToken = localStorage.getItem(STORAGE_KEY);
      if (accessToken) {
        setSession(accessToken);

        const res = await authApi.getUser();

        const { client } = res.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            user: client,
          },
        });

        await getCompanyInfo();
      } else if (urlToken && domainData?.company_id) {
        const loginRes = await authApi.getLoginToken({
          token: urlToken,
          company_id: domainData?.company_id,
        });

        const { token, account } = loginRes.data;

        setSession(token);

        dispatch({
          type: 'INITIAL',
          payload: {
            user: account,
          },
        });

        await getCompanyInfo();
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
            company: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
          company: null,
        },
      });
    }
  }, [domainData?.company_id]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password, company_id) => {
    const data = {
      email,
      password,
      company_id,
    };

    const res = await authApi.login(data);
    const { company, token } = res.data;

    setSession(token);

    await getCompanyInfo();

    const resUser = await authApi.getUser();

    const { client } = resUser.data;

    dispatch({
      type: 'LOGIN',
      payload: {
        account: client,
        company,
      },
    });
  }, []);

  const register = useCallback(async (data, token) => {
    await authApi.register(data, token);
    enqueueSnackbar('Account successfully created!');
  });

  // LOGOUT
  const logout = async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      account: state.account,
      company: state.company,
      domainData: state.domainData,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      initialize,
      logout,
      getCompanyInfo,
    }),
    [
      initialize,
      login,
      register,
      logout,
      getCompanyInfo,
      state.user,
      state.account,
      state.company,
      state.domainData,
      status,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
